import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";
import {
  Headline2Sans,
  Headline4Sans,
  ButtonPrimary,
} from "@userbrain/website-ui";

import * as styles from "./styles.module.css";

export function ResourceDownloadTeaserGrid(props) {
  const { className, headline, items, ...containerProps } = props;

  return (
    <div className={cn(className)} {...containerProps}>
      {headline && (
        <Headline2Sans className={styles.headline}>{headline}</Headline2Sans>
      )}
      <div className={styles.resourceTeaserGrid}>
        {items.map((item, i) => (
          <div key={i} className={styles.resourceTeaserCard}>
            <img
              className={styles.resourceTeaserCard_image}
              src={item.imgSrc}
              alt={item.imgAlt}
            />
            <Headline4Sans className={styles.resourceTeaserCard_title}>
              {item.title}
            </Headline4Sans>
            <span className={styles.resourceTeaserCard_text}>{item.text}</span>
            <ButtonPrimary
              className={styles.resourceTeaserCard_download}
              customTag={Link}
              to={"/resources"}
            >
              Download
            </ButtonPrimary>
          </div>
        ))}
      </div>
    </div>
  );
}
