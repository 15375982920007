import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { Overline, Headline4Sans } from "@userbrain/website-ui";

import * as styles from "./styles.module.css";

export function ThreeColumnsCards(props) {
  const {
    className,
    overline,
    card1TopComponent,
    card1Title,
    card1Subtext,
    card1Href,
    card2TopComponent,
    card2Title,
    card2Subtext,
    card3TopComponent,
    card2Href,
    card3Title,
    card3Subtext,
    card3Href,
    below,
  } = props;

  return (
    <div className={cn(className, styles.threeColumnsCards)}>
      <Overline className={styles.overlineText}>{overline}</Overline>
      <div className={styles.columnCardsContainer}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <a
              className={cn(styles.columnCard, styles.columnCard1)}
              href={card1Href}
            >
              <div className={styles.columnCard_content}>
                <div className={styles.columnCard_content_top}>
                  {card1TopComponent}
                  <Headline4Sans className={styles.cardTitle}>
                    {card1Title}
                  </Headline4Sans>
                </div>
                <span className={styles.cardSubtext}>{card1Subtext}</span>
              </div>
            </a>
          </div>
          <div className={styles.column}>
            <a
              className={cn(styles.columnCard, styles.columnCard2)}
              href={card2Href}
            >
              <div className={styles.columnCard_content}>
                <div className={styles.columnCard_content_top}>
                  {card2TopComponent}
                  <Headline4Sans className={styles.cardTitle}>
                    {card2Title}
                  </Headline4Sans>
                </div>
                <span className={styles.cardSubtext}>{card2Subtext}</span>
              </div>
            </a>
          </div>
          <div className={styles.column}>
            <a
              className={cn(styles.columnCard, styles.columnCard3)}
              href={card3Href}
            >
              <div className={styles.columnCard_content}>
                <div className={styles.columnCard_content_top}>
                  {card3TopComponent}
                  <Headline4Sans className={styles.cardTitle}>
                    {card3Title}
                  </Headline4Sans>
                </div>
                <span className={styles.cardSubtext}>{card3Subtext}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      {below && <div className={styles.belowCards}>{below}</div>}
    </div>
  );
}
